.footer_column__social:hover svg .fill {
    fill: #4f82ec;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 9;
    background: rgba(227, 231, 235, 0.64);
}


.footer-enter {
	opacity: 0;
  }
  .footer-enter-active {
	opacity: 1;
	transition: opacity .17s;
  }
  .footer-exit {
	opacity: 1;
  }
  .footer-exit-active {
	opacity: 0;
	transition: opacity .17s;
  }
  .footer__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


.footer__container {
    background: #fff;
    border-radius: 24px;
    padding: 3em 4em 2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (max-width: 768px) {
    .footer__container {
        padding:1.75em;
    }
}

.footer__container:nth-child(2) {
    padding: 2em 0;
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 3em;
}

@media (max-width: 768px) {
    .footer__wrap {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.footer__line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 1.3em;
}

.footer__line--center {
    padding: .8em 0;
    border-top: 1px solid #eef1f4;
    border-bottom: 1px solid #eef1f4;
}

@media (max-width: 768px) {
    .footer__line--last {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .footer__line--last .footer__line_right {
        padding-top: 2em;
        border-top: 1px solid #eef1f4;
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .footer__line--last .footer__line_left {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        padding-bottom: 1.2em;
    }
}

.footer__line_left,.footer__line_right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer__line_meta {
    text-align: right;
    font-style: italic;
    font-size: .8em;
    line-height: 1.8em;
    color: #6e6d7a;
    margin: 0;
	font-family: 'MuseoSansCyrl';
    font-weight: 300;
}

.footer__line_text {
    font-size: .8em;
    line-height: 1.3em;
    margin-right: 1.7em;
	font-family: 'MuseoSansCyrl';
    font-weight: 300;
}

.footer__line_img--2 {
    margin-right: .7em;
}

@media (max-width: 768px) {
    .footer__line_img--1,.footer__line_img--2 {
        width:92px;
        height: 35px;
    }

    .footer__line_img--1 .image,.footer__line_img--2 .image,.footer__line_img--3 .image {
        width: 100%;
        height: 100%;
    }

    .footer__line_img--2 {
        margin-right: 0;
        margin-left: .5em;
    }

    .footer__line_img--3 {
        width: 92px;
        height: 35px;
        margin-right: .5em;
    }
}

.footer__line_card+.footer__line_card {
    margin-left: .7em;
}

.footer__copyright {
    font-size: .9em;
    margin: 0;
    font-family: 'MuseoSansCyrl';
    font-weight: 300;
}

.footer_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 3vw;
}

@media (max-width: 768px) {
    .footer_column {
        width:100%;
        margin-right: 0;
    }
}

.footer_column:nth-child(1) {
    width: 20rem;
    margin-right: auto;
}

@media (max-width: 768px) {
    .footer_column:nth-child(1) {
        width:100%;
        margin-right: 0;
    }
}

.footer_column:last-child {
    margin-right: 0;
}

.footer_column__logo {
    width: 90%;
    height: 4em;
    margin-bottom: 2em;
}

.footer_column__logo img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}

.footer_column__lower {
    font-size: 0.875rem;
    font-family: 'MuseoSansCyrl';
    font-weight: 300;
    line-height: 1.6;
    margin: 0;
}

.footer_column__caption {
    font-family: 'Golos';
    font-weight: 500;
    color: #151515;
    font-size: 1.4em;
    line-height: 1em;
    margin-bottom: 1.25em;
}

@media (max-width: 768px) {
    .footer_column__caption {
        margin-bottom:.8em;
        font-size: 1.7em;
    }
}

.footer_column__link {
    padding: .25em;
    font-weight: 500;
    font-size: .9em;
    font-family: 'Inter';
    text-align: left;
    color: #757575;
}

.footer_column__link:hover {
    color: #151515;
}

.footer_column__link+.footer_column__link {
    margin-top: .75em;
}

.footer_column__socials {
    margin-top: 1.1em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer_column__social {
    width: 20px;
    height: 20px;
}

.footer_column__social .fill {
    -webkit-transition: fill .3s;
    -o-transition: fill .3s;
    transition: fill .3s;
}

.footer_column__social+.footer_column__social {
    margin-left: .5em;
}

.footer_column__social svg {
    width: 100%;
    height: 100%;
    fill: #757575;
}

@media (max-width: 768px) {
    .footer_column+.footer_column {
        margin-top:1.7em;
    }
}

.itome {
    margin-top: 1.25em;
    overflow: visible;
    font-size: .7em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

@media (max-width: 500px) {
    .itome {
        margin:2em 0;
    }
}

.itome:hover .itome_tooltip {
    -webkit-transform: translateX(calc(-50%)) translateY(-2vh);
    -ms-transform: translateX(calc(-50%)) translateY(-2vh);
    transform: translateX(calc(-50%)) translateY(-2vh);
    opacity: 1;
}

.itome__title,.itome_tooltip {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.itome__title {
    color: #212121;
    font-weight: 500;
    font-size: 1.2em;
    font-family: 'MuseoSansCyrl';
}

.itome__title svg {
    margin: 0 .25em;
    fill: #f2453d;
    width: 1em;
    height: 1em;
}

.itome_tooltip {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    bottom: 105%;
    left: 50%;
    -webkit-transform: translateX(calc(-50%)) translateY(1vh);
    -ms-transform: translateX(calc(-50%)) translateY(1vh);
    transform: translateX(calc(-50%)) translateY(1vh);
    background: #fff;
    -webkit-box-shadow: 2px 15px 20px rgba(0,0,0,.15);
    box-shadow: 2px 15px 20px rgba(0,0,0,.15);
    border-radius: 15px;
    padding: 2em;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    opacity: 0;
    pointer-events: none;
}

@media screen and (max-width: 480px) {
    .itome_tooltip {
        display:none;
    }
}

.itome_tooltip:before {
    content: '';
    position: absolute;
    bottom: -13px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 7px solid transparent;
    border-top-color: #fff;
}

.itome_tooltip__logo {
    width: 4em;
    height: 4em;
    background: -webkit-gradient(linear,left top,left bottom,color-stop(9.59%,#006eff),color-stop(99.64%,#0059dc));
    background: -webkit-linear-gradient(top,#006eff 9.59%,#0059dc 99.64%);
    background: -o-linear-gradient(top,#006eff 9.59%,#0059dc 99.64%);
    background: linear-gradient(180deg,#006eff 9.59%,#0059dc 99.64%);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	margin-bottom: 0.5rem;
}

.itome_tooltip__logo svg {
    position: relative;
    left: -1px;
    top: -1px;
    width: 50%;
    fill: #fff;
}

.itome_tooltip__title {
    font-weight: 700;
    font-size: 1.15em;
    color: #000;
	margin-bottom: .2rem;
    font-family: 'MuseoSansCyrl';
}

.itome_tooltip__subtitle {
    color: #b6b6b6;
    font-size: .875em;
    font-weight: 300;
    white-space: nowrap;
    font-family: 'MuseoSansCyrl';
}


@media (max-width: 768px) {
    .desktop_only {
        display: none!important;
    }
}

@media (min-width: 769px) {
    .mobile_only {
        display:none!important;
    }
}

.footer__content {
	position: relative;
    background: white;
    margin: 0 auto;
    border-radius: 16px 16px 0 0;
    width: calc(100% - 9.25rem);
	opacity: 0;
	transform: translate3d(0, 5rem, 0);
	transition: opacity .17s, transform .17s;
}

  
.footer-enter-done .footer__content {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .footer__content-exit {
	opacity: 1;
  }
  .footer__content-exit-active {
	opacity: 0;
	transform: translate3d(0, 5rem, 0);
	transition: opacity .17s, transform .17s;
  }
  .footer__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}


.footer__hide_btn {
    width: 2.125rem;
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 0;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background .17s, opacity .17s .1s, transform .17s .1s;
	opacity: 0;
	transform: translate3d(0, 2rem, 0);
}
.footer-enter-done .footer__hide_btn {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }

.footer__hide_btn svg {
    width: 50%;
    height: 50%;
    transform: rotate(90deg);
}

.footer__hide_btn:hover {
    background: #EEF1F4;
}
