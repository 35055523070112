
.header {
    position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
    padding: 1.25rem;
    z-index: 3;
}
@media (max-width: 768px) {
    .header {
      margin: 0  1.71429rem;
      border-radius: 10px;
      padding: 1.14286rem;
    }
  }

.header__main_link {
	display: block;
	transition: filter .17s;
}
.header__logo {
	width: 9.875rem;
	height: 2rem;
	object-fit: cover;
}
.header__main_link:hover {
	filter: contrast(2) brightness(0.9)
}

.header__search {
    flex: 1;
    margin-left: 2.5625rem;
}

/* search styles */
.header__search {
    flex: 1;
    margin-left: 2.5625rem;
}



/* end search styles */

.header__modal_wrapper {
    position: relative;
}
.header_btn {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #EEF1F4;
    border-radius: 8px;
    padding: 0.563rem 0.8125rem;
    cursor: pointer;
    transition: background .17s;
    background: white;
}

.header_btn__icon {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: .5rem;
	fill: #151515;
    transition: fill .17s;
}

.header_btn:hover,
.header_btn:focus {
    background: #EEF1F4;
}

.header_btn:hover .header_btn__icon,
.header_btn:focus .header_btn__icon,
.header_btn.is-clicked .header_btn__icon {
    fill: #4F82EC;
}


.header_btn.is-clicked {
    background: #EEF2FF;
}

.header_btn__state_icon {
    width: 0.55rem;
    height: 0.55rem;
    transform: rotate(90deg);
    margin-left: .7rem;
    transition: transform .17s;
}
.is-clicked .header_btn__state_icon {
    transform: rotate(90deg) scaleX(-1);
}

.header_btn__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1;
}

.header__btns {
    margin-left: 2.25rem;
    display: flex;
    align-items: center;
}
.header__btn + .header__btn,
.header__btn + .header__modal_wrapper,
.header__modal_wrapper + .header__btn,
.header__modal_wrapper + .header__modal_wrapper
  {
	margin-left: 0.75rem;
} 


.header_btn .ripple_container {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    clip-path: none
}

.header_btn .ripple {
    background: #6d6d6d;
    animation-duration: .5s;
}

.geolocation_error {
    position: absolute;
    top: calc(100% + 2rem);
    right: 0;
    left: auto;
    min-width: 24em;
    background: white;
    border-radius: 8px;
    padding: 1rem;
    padding-right: 2rem;
    font-size: .875em;
    font-family: 'Inter';
    font-weight: 500;
    cursor: pointer;
    line-height: 1.3;
    text-align: left;
}

.geolocation_error__close {
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #151515;
    border-radius: 50%;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
    transition: background .17s;
}

.geolocation_error__close svg {
    fill: white;
    width: 50%;
    height: 50%;
}

.geolocation_error:hover .geolocation_error__close {
    background: #F2453D;
}


.geolocation_error-enter {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
  }
  .geolocation_error-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .17s, transform .17s;
  }
  .geolocation_error-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .geolocation_error-exit-active {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
	transition: opacity .17s, transform .17s;
  }

  .header_btn__preloader {
    width: 2.3em;
    height: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -.8em
}

@media (max-width: 768px) {
    .under_header_controls {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        overflow: scroll;
        padding: 0 1.71429rem;
    }
    .under_header_controls::-webkit-scrollbar{display:none;} 
    
    .header__btn {
        flex: none;
        white-space: nowrap;
        padding: 0.72rem;
    }
    .header_btn__text {
        font-size: 1rem;
    }
    .header_btn__icon {
        width: 0.9rem;
        height: 0.9rem;
    }
    
    .header__btn + .header__btn, .header__btn + .header__modal_wrapper, .header__modal_wrapper + .header__btn, .header__modal_wrapper + .header__modal_wrapper {
        margin-left: .57rem;
    }
    
    
    
    .header_btn__state_icon {
        display: none;
    }
    
    
}