
.form {
	width: 25.5rem;
	background: white;
	background: #FFFFFF;
	/* Block */
	box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
	border-radius: 16px;
	padding: 2.125rem;
  }
  
  .form__privacy_text {
	font-size: 0.625rem;
	text-align: center;
	line-height: 1.4;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	color: #6E6D7A;
  }
  
  .form__privacy_text a {
	color: #151515;
	margin: 0 0.6ch;
  }
  
  .form__title {
	font-family: 'Golos';
	font-style: normal;
	font-weight: 600;
	font-size: 1.875rem;
	margin: 0 0 0.7rem;
	white-space: pre-wrap;
	line-height: 1.13;

  }
  
  .form__text {
	font-family: 'MuseoSansCyrl';
	font-style: normal;
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.7;
	margin: 0;
	margin-bottom: 1.7em;
	white-space: pre-wrap;
	color: #6E6D7A;

  }

  .form {
	width: 25.5rem;
	background: white;
	background: #FFFFFF;
	/* Block */
	box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
	border-radius: 16px;
	padding: 2.125rem;
  }

  .form__content {
	  margin-bottom: 1rem;
	  transition: opacity .17s, transform .17s
  }
  .form__content.on-request {
    opacity: .5;
    pointer-events: none;
    transform: scale(.98);
}

.form__loader {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.form__button {
	width: 100%;
}
  
  .form__privacy_text {
	font-size: 0.625rem;
	text-align: center;
	line-height: 1.4;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	color: #6E6D7A;
	white-space: pre-wrap;

  }
  
  .form__privacy_text a {
	display: inline-block;
	color: #151515;
  }
  .form__input + .form__input {
	  margin-top: 1rem;
  }

  .form_success {
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
	  /* webpackIgnore: true */

    background: url('/assets/img/success_border.svg') no-repeat center;
    transform: rotate(-350deg) scale(.8);
    opacity: 0;
    animation: success .7s forwards;
}

@keyframes success {
    to {
        transform: none;
        opacity: 1;
    }
}

.form_success__mark {
    width: 40%;
    height: 40%;
    fill: #2B4BF2;
    opacity: 0;
    transform: scale(1.7);
    animation: success .4s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards .6s;
}

.form__succes_icon {
    margin-bottom: 1.25rem;
}


@media (max-width: 768px)  {
	.form__title {
		font-size: 1.71429rem;
		white-space: normal;
	}
	
	.form__text {
		font-size: 1rem;
	}
	
	.form {
		max-width: 26.14286rem;
		padding: 1.71429rem;
		border-radius: 10px;
		width: 100%;
	}
	
	.input__field {
		font-size: 1rem;
		line-height: 4;
		padding: 0 1.14286rem;
	}
	
	.input__field:focus ~ .input__label, .input.is-filled .input__label {
		transform: translate3d(0, -1.95rem, 0) scale(.857);
	}
	
	.form_success {
		width: 4.28571rem;
		height: 4.28571rem;
	}
}