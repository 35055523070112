.input {
    position: relative;
}


.input__field {
    border: 1px solid #EEF1F4;
    border-radius: 8px;
    width: 100%;
    font-size: 0.875rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 3.86;
    padding: 0 1rem;
    transition: background .17s;
}
.input--textarea .input__field  {
	resize: none;
	line-height: 1.2;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.input__label {
    position: absolute;
    left: 1rem;
    top: calc(50% - .5em);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1;
    pointer-events: none;
    color: #6E6D7A;
    transition: transform .17s;
}
.input--textarea .input__label {
	top: 1rem;
}

.input__field:focus ~ .input__label,
.input.is-filled .input__label {
    transform: translate3d(0, -1.75rem, 0) scale(.857);
    transform-origin: left;
}
.input--textarea .input__field:focus ~ .input__label,
.input--textarea.is-filled .input__label {
    transform: translate3d(0, -1.45rem, 0) scale(.857);
    transform-origin: left;
}

.input__field:focus {
    background: #F9FAFB;
	outline: none;
}

.input__label:before {
    content: '';
    position: absolute;
    left: -0.3em;
    right: -0.4em;
    top: 0.49em;
    bottom: .25em;
    background: #F9FAFB;
    z-index: -1;
    transition: transform .17s;
    transform: scaleX(0);
}

.input__field:focus ~ .input__label:before,
.input.is-filled  .input__label:before {
    transform: scaleX(1);
}
