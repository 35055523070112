

@font-face {
  font-family:'MuseoSansCyrl';src:url(./fonts/MuseoSansCyrl-300.woff2) format("woff2"),url(./fonts/MuseoSansCyrl-300.woff) format("woff");font-weight:300;font-style:normal
}

@font-face {
  font-family:'MuseoSansCyrl';src:url(./fonts/MuseoSansCyrl-500.woff2) format("woff2"),url(./fonts/MuseoSansCyrl-500.woff) format("woff");font-weight:500;font-style:normal
}
@font-face {
  font-family:'MuseoSansCyrl';src:url(./fonts/MuseoSansCyrl-700.woff2) format("woff2"),url(./fonts/MuseoSansCyrl-700.woff) format("woff");font-weight:700;font-style:normal
}
@font-face {
  font-family:'MuseoSansCyrl';src:url(./fonts/MuseoSansCyrl-900.woff2) format("woff2"),url(./fonts/MuseoSansCyrl-900.woff) format("woff");font-weight:900;font-style:normal
}


@font-face {
  font-family:'Golos';src:url(./fonts/Golos-300.woff2) format("woff2"),url(./fonts/Golos-300.woff) format("woff");font-weight:300;font-style:normal
}
@font-face {
  font-family:'Golos';src:url(./fonts/Golos-500.woff2) format("woff2"),url(./fonts/Golos-500.woff) format("woff");font-weight:500;font-style:normal
}
@font-face {
  font-family:'Golos';src:url(./fonts/Golos-600.woff2) format("woff2"),url(./fonts/Golos-600.woff) format("woff");font-weight:600;font-style:normal
}
@font-face {
  font-family:'Golos';src:url(./fonts/Golos-700.woff2) format("woff2"),url(./fonts/Golos-700.woff) format("woff");font-weight:700;font-style:normal
}
@font-face {
  font-family:'Golos';src:url(./fonts/Golos-900.woff2) format("woff2"),url(./fonts/Golos-900.woff) format("woff");font-weight:900;font-style:normal
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ExtraBoldItalic.woff2') format('woff2'),
      url('./fonts/Inter-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ExtraBold.woff2') format('woff2'),
      url('./fonts/Inter-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-BoldItalic.woff2') format('woff2'),
      url('./fonts/Inter-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ExtraLight.woff2') format('woff2'),
      url('./fonts/Inter-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Italic.woff2') format('woff2'),
      url('./fonts/Inter-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Light.woff2') format('woff2'),
      url('./fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ExtraLightItalic.woff2') format('woff2'),
      url('./fonts/Inter-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-LightItalic.woff2') format('woff2'),
      url('./fonts/Inter-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Medium.woff2') format('woff2'),
      url('./fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.woff2') format('woff2'),
      url('./fonts/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-MediumItalic.woff2') format('woff2'),
      url('./fonts/Inter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-ThinItalic.woff2') format('woff2'),
      url('./fonts/Inter-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
      url('./fonts/Inter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Thin.woff2') format('woff2'),
      url('./fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-SemiBold.woff2') format('woff2'),
      url('./fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter V';
  src: url('./fonts/InterV.woff2') format('woff2'),
      url('./fonts/InterV.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Black.woff2') format('woff2'),
      url('./fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-BlackItalic.woff2') format('woff2'),
      url('./fonts/Inter-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold.woff2') format('woff2'),
      url('./fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


* {
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-appearance: none;

}



html {
   font-family: 'Inter', sans-serif;
   font-size: 16px;	
   color: #151515;
}

@media (max-width: 1376px) {
  html  {
    font-size: 14px;
  }
}

@media (max-width: 1280px) {
  html  {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  html  {
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  html {
      font-size: 13px;
  }
}

@media (max-width: 375px) {
  html {
      font-size: 12px;
  }
}
.App {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.125rem 4.625rem;
}

@media (max-width: 1080px) {
  .App {
    padding: 1rem;
  }
}
@media (max-width: 768px) {
  .App {
    padding: 1.71429rem 0;
  }
}
html, body, #root, .App {
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

:root {
  --app-height: 100%;
}

a  {
  text-decoration: none;
}
button {
  color: inherit;
  padding: 0;
  font-size: inherit;
}


input {
  font: inherit;
  border: none;
  background: none;
  outline: none;
}
.data_preloader {
  position: fixed;
  width: 4.625rem;
  height: 4.625rem;
  z-index: 9;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  /* Block */
  box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
}

.data_preloader svg {
  width: 100%;
  height: 100%;
}


.app__header_container  {
  position: relative;
  z-index: 2;
}

.app__bottom_controls {
  position: relative;
  z-index: 8;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  pointer-events: none;
}

.app_controls {
  pointer-events: all;
}




.app_controls__item + .app_controls__item {
  margin-top: 0.75rem;
}

.app_control_btn {
  padding: 0.75rem 0.875rem;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  pointer-events: none;
}

.app_control_btn__icon_wrapper {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: black;
  margin-right: 0.75rem;
  position: relative;
  transition: background .25s
}

.app_control_btn__icon {
  width: 50%;
  height: 50%;
  fill: white;
}

.app_control_btn__text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  color: #151515;
  position: relative;
  opacity: 0;
  transition: opacity .1s, transform .17s;
  transform: translateX(-3rem);
}

.app_controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.app_control_btn__bg {
  content: '';
  position: absolute;
  left: -25rem;
  width: 28.2rem;
  top: 0;
  bottom: 0;
  background: white;
  border-radius: 10px;
  transition: transform 0.3s;
  box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
  pointer-events: all;
}

.app_control_btn:hover .app_control_btn__bg {
  transform: translate3d(calc(100% - 3.2rem), 0, 0);
  transition-duration: 0.4s;
}

.app_control_btn:hover .app_control_btn__text {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.17s .05s, transform 0.17s .05s;
}

.app_control_btn__content {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.app_control_btn:hover .app_control_btn__icon_wrapper--blue {
  background: #4F82EC
}

.app_control_btn:hover .app_control_btn__icon_wrapper--pink {
  background: #FE8597;
}

.app_control_btn:hover .app_control_btn__icon_wrapper--green {
  background: #3BDBB9;
}


@media (max-width: 768px) {
  .app__bottom_controls {
    margin: 0 1.71429rem 1rem;
}

  .app_controls {
    flex-direction: row;
    width: 100%;
}

.app_controls__item + .app_controls__item {
    margin-top: 0;
    margin-left: 1em;
}

.app_control_btn__bg {
    left: 0;
    width: 100%;
}

.app_control_btn__content {
    pointer-events: all;
}

.app_control_btn__text {
    opacity: 1;
    transform: none;
}
.app_controls__item {
  flex: 1;
  max-width: 15rem;
}

.app_control_btn__content {
  justify-content: center;
}

.app_control_btn__icon_wrapper {
  width: 1.07692rem;
  height: 1.07692rem;
}


.app_controls {
  justify-content: center;
}


.app_control_btn__text {
  font-size: 1rem;
}
.app_control_btn:hover .app_control_btn__bg {
  transform: none;
}

}

.big_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4F82EC;
  border-radius: 10px;
  color: white;
  padding: 1.125rem;
  cursor: pointer;
  transition: background .17s;
}



.big_btn__icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  fill: white
}

.big_btn__text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
}

.big_btn:hover {
  background: #4380ff;
}

.big_btn--grey {
  background: #EEF1F4;
  color: #151515;
}

.big_btn--grey:hover {
  background: #dedede;
}


.inline_btn {
  font-size: 0.75rem;
  padding: .5em 0.75em;
  background: #EEF1F4;
  border-radius: 4px;
  cursor: pointer;
  transition: background .17s;
  font-family: 'Inter';
  font-weight: 500;
}

.inline_btn:hover {
  background: #e7e7e7;
}


.modal_back_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  transition: color .17s;
}
.modal_back_btn:hover  {
  color: #4F82EC;
}

.modal_back_btn__text {
  font-family: 'Inter';
  font-weight: 500;
  font-size: .875rem;
  
}
@media (max-width: 768px) {
  .modal_back_btn__text {
    font-size: 1rem;
  }
}

.modal_back_btn__icon {
  fill: #4F82EC;
  width: 0.6rem;
  height: 0.7rem;
  margin-right: 1rem;
  transform: scaleX(-1);
}

.modal_close_btn {
  width: 1.42857rem;
  height: 1.42857rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF1F4;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
}

.modal_close_btn__icon {
  width: 40%;
  height: 40%;
  fill: #767581;
}

.modal_controls {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.71429rem;
  position: sticky;
  top: 0;
  background: white;
  border-bottom: 1px solid #EEF1F4;
}
