.filters_list {
    position: absolute;
    top: calc(100% + 1.375rem);
    left: 0;
    background: white;
    border-radius: 14px;
    overflow: hidden;
    width: 28.3125rem;
}

.filters_category {
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    text-align: left;
    transition: background .17s;
cursor: pointer;
}

.filters_category__head {
    display: flex;
    align-items: center;
    margin-bottom: 0.375rem;
}

.filters_category__icon {
    position: relative;
    width: 2.125rem;
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.25rem;
    border-radius: 10px;
    transition: background .17s
}
.filters_category__count {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    font-size: 0.6rem;
    line-height: 1;
    font-family: 'Inter';
    font-weight: 500;
    width: 1rem;
    height: 1rem;
    background: #fe8597;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
}


.filters_category__icon_content {
    width: 55%;
    height: 55%;
}

.filters_category__icon--need_help {
    background: #FFEEEE;
}

.filters_category__icon--need_help .filters_category__icon_content {
    fill: #FE8597;
}

.filters_category__icon--need_help {
    background: #FFEEEE;
}

.filters_category__icon--need_help .filters_category__icon_content {
    fill: #FE8597;
}

.filters_category__icon--wanna_help {
    background: #EEF2FF;
}

.filters_category__icon--wanna_help .filters_category__icon_content {
    fill: #4F82EC;
    ; }

.filters_category__icon--services {
    background: #d2fdf4;
}

.filters_category__icon--services .filters_category__icon_content {
    fill: #3BDBB9;
}

.filters_category__icon--events {
    background: #FFFDE7;
}

.filters_category__icon--events .filters_category__icon_content {
    fill: #FBC02D;
}


.filter_category__name {
    margin: 0;
    font-family: 'Golos';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
}

.filter_category__arrow {
    fill: #4F82EC;
    width: 0.7rem;
    height: 0.9rem;
    margin-left: 1.1rem;
    margin-top: 0.3rem;
}

.filter_category__description {
    font-family: 'MuseoSansCyrl';
    font-size: 0.875rem;
    color: #6E6D7A;
    font-weight: 300;
    line-height: 1.58;
    margin: 0;
    margin-left: 3.375rem;
}

.filters_category:hover {
    background: #F9FAFB;
}


.filters_category:hover .filters_category__icon {
    background: white
}

.filters_list .simplebar-scrollbar {
    width: 2px;
    background: #EEF1F4;
}

.filters_list .simplebar-scrollbar.simplebar-visible:before {
    width: 2px;
    left: 0;
    right: 0;
    opacity: 1;
    background: #6E6D7A;
}

.filters_list .simplebar-track.simplebar-vertical {
    width: 2px;
    background: #EEF1F4;
    right: 1rem;
    top: 1rem;
    bottom: 1rem;
}


.filters_list__container-enter {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
  }
  .filters_list__container-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .17s, transform .17s;
  }
  .filters_list__container-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .filters_list__container-exit-active {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
	transition: opacity .17s, transform .17s;
  }
/* 

.filters_list__list-enter {
	opacity: 0;
  }
.filters_list__list-enter-active {
	opacity: 1;
	transition: opacity 200ms;
  }
.filters_list__list-exit {
	opacity: 1;
  }
.filters_list__list-exit-active {
	opacity: 0;
	transition: opacity 200ms;
  } */

  .filters_list__list-exit.filters_list__categories{
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  .filters_list__list-exit-active.filters_list__categories{
    transform: translate3d(-5rem, 0, 0);
    opacity: 0;
    transition: transform .1s, opacity .1s;
  }
  .filters_list__list-enter.filters_list__filters_wrapper{
      transform: translate3d(5rem, 0, 0);
      opacity: 0;
    }
.filters_list__list-enter-active.filters_list__filters_wrapper{
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform .1s, opacity .1s;
  }

 
  .filters_list__list-enter.filters_list__filters_wrapper{
      transform: translate3d(5rem, 0, 0);
      opacity: 0;
    }
.filters_list__list-enter-active.filters_list__filters_wrapper{
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform .1s, opacity .1s;
  }

   .filters_list__list-enter.filters_list__categories{
       transform: translate3d(-5rem, 0, 0);
       opacity: 0;
}
.filters_list__list-enter-active.filters_list__categories{
transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform .1s, opacity .1s;
  }
  
  .filters_list__list-exit.filters_list__filters_wrapper{
      opacity: 1;
      transform: translate3d(0, 0, 0);
}
.filters_list__list-exit-active.filters_list__filters_wrapper{
    transform: translate3d(5rem, 0, 0);
    opacity: 0;
  transition: transform .1s, opacity .1s;
}

.filters_back_btn {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
    display: flex;
    align-items: center;
    padding: 1.5rem 1rem 1.5rem;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.875rem;
    border-bottom: 1px solid #EEF1F4;
    transition: background .17s;
    width: 100%;
    cursor: pointer;
}

.filters_back_btn__icon {
    width: 0.7rem;
    height: 0.7rem;
    fill: #4F82EC;
    margin-right: 1rem;
    margin-left: .5rem;
    transform: scaleX(-1);
}

.filters_back_btn:hover {
    background: #F9FAFB;
}


.filters_list__filters_wrapper {
    text-align: left;
}

.filters_list__category_title {
    font-family: 'Golos';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0;
    padding: 1.5rem;
}

.filter {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
    transition: background .17s;
    width: 100%;
    cursor: pointer;
    text-align: left;
}
.filter:hover {
    background: #F9FAFB;
}

.filter__icon {
    width: 2.125rem;
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EEF1F4;
    border-radius: 9.27273px;
    margin-right: 0.75rem;
    flex: none;
    position: relative;
    transition: background .17s;
}

.filter__icon_content {
    width: 60%;
    height: 60%;
    transition: transform .17s, opacity .17s;
}

.filter__text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.875rem;
}

.filters_list__item + .filters_list__item {
    position: relative;
}

.filters_list__item + .filters_list__item:before {
    content: '';
    position: absolute;
    left: 4.5rem;
    right: 0;
    top: 0;
    height: 1px;
    background: #EEF1F4;
}

.filters_list .simplebar-track.simplebar-horizontal {
    display: none;
}


.filter__icon_success {
    width: 40%;
    height: 40%;
    fill: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    transition: transform .17s, opacity .17s;
    transform: scale(.7);
    opacity: 0;
}

.is-selected .filter__icon_success {
    transform: scale(1);
    opacity: 1;
}

.is-selected .filter__icon_content {
    opacity: 0;
    transform: translate3d(-1rem, 0, 0);
}

.filter--need_help.is-selected .filter__icon {
    background: #FE8597;
}
.filter--wanna_help.is-selected .filter__icon {
    background: #4F82EC;
}
.filter--services.is-selected .filter__icon {
    background:  #3BDBB9;
}

.filters_list__btns {
    position: sticky;
    bottom: 0;
    padding: 1.25rem;
    background: white;
    border-top: 1px solid #EEF1F4;
    text-align: right;
}

.filters_list__btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.625rem 1.25rem;
    border-radius: 8px;
    transition: background .17s;
    cursor: pointer;
}

.filters_list__clear {
    color: #F2453D;
    border: 1px solid #EEF1F4;
}

.filters_list__apply {
    background: #4F82EC;
    color: white;
    margin-left: 0.75rem;
}

.filters_list__clear:hover {
    background: #fff3f3;
}

.filters_list__apply:hover {
    background: #346ad7;
}


.filters_list__btns-enter {
	opacity: 0;
	transform: translate3d(0, 1rem, 0);
  }
  .filters_list__btns-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .17s, transform .17s;
  }
  .filters_list__btns-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .filters_list__btns-exit-active {
	opacity: 0;
	transform: translate3d(0, 1rem, 0);
	transition: opacity .17s, transform .17s;
  }


  

.filter .ripple_container {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    clip-path: none
}

.filter .ripple {
    background: rgb(215, 215, 215);
    animation-duration: .7s;
}

@media (max-width: 768px) {
    .filters_list_wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(227, 231, 235, 0.64);
        z-index: 9;
        opacity: 0;
        pointer-events: none;
        transition: opacity .17s;
        padding: 1.71429rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .filters_list_wrapper.is-visible {
        opacity: 1;
        pointer-events: all;
    }
    
    .filters_list {
        position: relative;
        top: auto;
        width: 100%;
        max-width: 28.3125rem;
    }
    
    .filters_list__container-exit-active {
        transform: translate3d(-6rem, 0, 0);
    }


.filter_category__name {
    font-size: 1.71429rem;
    line-height: 1;
    font-weight: 500;
}

.filter_category__arrow {
    height: 0.7em;
}

.filters_category__icon {
    width: 2.42857rem;
    height: 2.42857rem;
    margin-right: 1rem;
}


.filter_category__description {
    font-size: 1rem;
    margin-left: 3.42857rem;
}

.filter__text {
    font-size: 1rem;
    line-height: 1.3;
}

.filter {
    padding: 1.21429rem 1.71429rem;
}

.filters_list__category_title {
    font-weight: 600;
    padding: 1rem 1.71429rem;
    font-size: 1.71429rem;
}

.filter__icon {
    width: 2.42857rem;
    height: 2.42857rem;
    margin-right: 0.85714rem;
}
.applied_filters {
    left: 1.71429rem;
    right: 1.71429rem;
    width: auto;
    padding: 0;
    top: 9.5rem;
}

.hidden_filters__list {
    right: auto;
    left: 0;
}

.applied_filter__text {
    font-size: 1rem;
}


}