.cities_list {
	position: absolute;
    top: calc(100% - 2rem);
    z-index: 1;
	width: 100%;
    border-radius: 0px 0px 14px 14px;
    overflow: hidden;
}

.cities_list__container {
    background: #FFFFFF;
    padding: 2rem 0;
	display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
	width: 100%;

}

.cities_list__container-enter {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
  }
  .cities_list__container-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .17s, transform .17s;
  }
  .cities_list__container-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .cities_list__container-exit-active {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
	transition: opacity .17s, transform .17s;
  }
  .cities_list__region {
    width: 50%;
    padding-right: 9em;
    padding-top: 2.125rem;
    padding-bottom: 2.125rem;
    padding-left: 2.125rem;
    border-top: 1px solid #EEF1F4;
}

@media (max-width: 1500px) {
    .cities_list__region {
        padding-right: 5rem;
    }
}
@media (max-width: 1440px) {
    .cities_list__region {
        padding-right: 2rem;
    }
}

.cities_list_city {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    line-height: 1;
    padding: 0.5625rem 0;
}

.cities_list_city__icon {
    width: 0.75rem;
    height: 0.75rem;
    fill: #6E6D7A;
    margin-right: .75rem;
    flex: none;
    transition: fill .17s;
}

.cities_list_city__name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #6E6D7A;
    flex: none;
    transition: color .17s;
}

.cities_list_region__cities {
    height: 10rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
}
@media (max-width: 1000px) {
    .cities_list_region__cities {
        height: 14rem;
    }
}

.cities_list_city:hover .cities_list_city__name,
.cities_list_city.is-active .cities_list_city__name {
    color: #151515;
}

.cities_list_city:hover .cities_list_city__icon {
    fill: #151515;
}

.cities_list_city.is-active .cities_list_city__icon {
    fill:  #4F82EC;
}

.cities_list_region__title {
    font-family: 'Golos';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0 0 1.4375rem;
}

.cities_list__region:nth-of-type(odd) {
    border-right: 1px solid #EEF1F4;
}



.cities_list .simplebar-scrollbar {
    width: 2px;
    background: #EEF1F4;
}

.cities_list .simplebar-scrollbar.simplebar-visible:before {
    width: 2px;
    left: 0;
    right: 0;
    opacity: 1;
    background: #6E6D7A;
}

.cities_list .simplebar-track.simplebar-vertical {
    width: 2px;
    background: #EEF1F4;
    right: 1rem;
    top: 3rem;
    bottom: 3rem;
}


@media (max-width: 768px) {
    .cities_list {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(227, 231, 235, 0.64);
        z-index: 9;
        opacity: 0;
        pointer-events: none;
        transition: opacity .17s;
        padding: 1.71429rem;
    }
    
    .cities_list.is-visible {
        opacity: 1;
        pointer-events: all;
    }
    
    .cities_list__container {
        display: block;
        padding-top: 0;
    }
    
    .cities_list__region {
        width: 100%;
    }
    
    .cities_list__region:nth-of-type(odd) {
        border-right: none;
    }
    
    .cities_list_region__cities {
        height: auto;
    }
    
    .cities_list__region:first-of-type {
        border-top: none;
        padding-top: 0;
    }
    .cities_list__wrapper {
        box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }
    
    .cities_list_region__title {
        font-weight: 600;
        font-size: 1.71429rem;
        margin-bottom: 1.5rem;
        line-height: 1;
    }
    .cities_list_city__name {
        font-size: 1rem;
    }
    
    .cities_list__region {
        padding: 1rem 1.82857rem;
    }

    .cities_list__container-exit-active {
        transform: translate3d(-6rem, 0, 0);
    }
    .cities_list_city {
        padding: 0.9rem 0;
    }
    .cities_list_city__icon {
        width: 0.85rem;
        height: 0.85rem;
    }
    
    
    
    
  }