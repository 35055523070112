.filters_category__count {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    font-size: 0.6rem;
    line-height: 1;
    font-family: 'Inter';
    font-weight: 500;
    width: 1rem;
    height: 1rem;
    background: #fe8597;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
}

.applied_filters {
	position: absolute;
	left: 0;
	right: 0;
    width: 100%;
    
    padding: 0.875rem 0;
	display: flex;
}
.applied_filters-list {
	display: flex;
    align-items: center;
}

.applied_filter {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #EEF1F4;
    border-radius: 8px;
    padding: 0.5rem 0.75rem;
	cursor: pointer;
}

.applied_filter__remove_icon {
    width: 1rem;
    height: 1rem;
    background: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .5rem;
    transition: background .17s;
}

.applied_filter__remove_icon_content {
    width: 50%;
    height: 50%;
    fill: white;
}

.applied_filters__item {
    margin-right: 0.5rem;
    flex: none;
	position: absolute;
	left: 0;
	transition: transform .2s;
	
}
@keyframes filter {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.applied_filter__text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.875rem;
    max-width: 14.125rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.applied_filter:hover .applied_filter__remove_icon {
    background: #F2453D;
}

  
  .hidden_filters__list {
    position: absolute;
    right: 0;
    top: calc(100% + .5rem);
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.06);
    border-radius: 14px;
    padding: 0.875rem;
}

.hidden_filters__item {
    max-width: 18.125rem;
    width: 100%;
    justify-content: space-between;
}

.hidden_filters__item + .hidden_filters__item {
    margin-top: 0.75rem;
}


.hidden_filters__container {
}
.hidden_filters__container-enter {
	opacity: 0;
	transform: scale(.7);
	transform-origin: right top;
  }
  .hidden_filters__container-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .12s, transform .12s;
  }
  .hidden_filters__container-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .hidden_filters__container-exit-active {
	opacity: 0;
	transform: scale(.7);
	transform-origin: right top;
	transition: opacity .12s, transform .12s;
  }

  .applied_filter__arrow {
    width: 0.6rem;
    height: 0.6rem;
    fill: black;
    margin-left: .75rem;
    transform: rotate(90deg);
    transition: transform .17s, fill .17s;
}

.hidden_filters__toggle.is-clicked {
    background: #EEF2FF;
    /* Stroke */
    border-color: #EEF1F4;
}

.hidden_filters__toggle {
    transition: background .17s, border-color .17s;
}

.is-clicked .applied_filter__arrow {
    transform: rotate(90deg) scaleX(-1);
    fill: #4F82EC;
}
