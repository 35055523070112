.menu-enter {
	opacity: 0;
  }
  .menu-enter-active {
	opacity: 1;
	transition: opacity .17s;
  }
  .menu-exit {
	opacity: 1;
  }
  .menu-exit-active {
	opacity: 0;
	transition: opacity .17s;
  }

  .menu {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(227, 231, 235, 0.64);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.menu__content {
    position: relative;
    width: 23.0625rem;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.06);
    padding: 3rem 2.125rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
	transition: transform .17s, opacity .17s;
    opacity: 0;
    transform: translate3d(5rem, 0, 0);
}

.menu-enter-done .menu__content {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}


@media (max-height: 700px) {
    .menu__content {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.menu_link {
    display: flex;
    align-items: center;
    padding: 1rem 1.46rem;
    border-radius: 10px;
    transition: background .17s;
}

.menu_link__icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 1.46rem;
    fill: #6E6D7A;
    transition: fill .17s;
}

.menu_link__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    color: #6E6D7A;
    transition: color .17s;
}

.menu__link {
    width: 100%;
}

.menu_link:hover {
    background: #EEF2FF;
}

.menu_link:hover .menu_link__text {
    color: #151515;
}

.menu_link:hover .menu_link__icon {
    fill: #4F82EC;
}

.menu__logo {
    text-align: center;
    margin-bottom: auto;
    height: 10vh;
	min-height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.menu__logo_img {
    object-fit: contain;
    width: 100%;
    height: 60%;
}

.menu__links {
    margin-bottom: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    max-height: 35rem;
}

@media (max-height: 700px) {
    .menu__links {
        margin-top: 1rem;
    }
}

@media (max-width: 768px)  {
    .menu_link__text {
        font-size: 1rem;
    }
    
    .menu__content {
        padding: 1.71429rem;
    }
    
    .menu__links {
        max-height: 46rem;
        margin-top: auto;
        height: calc(100% - 10rem);
    }
    
    .menu__content [data-simplebar], .menu__content .simplebar-content {
        height: 100%;
    }
    
    .menu__content .simplebar-content {
        display: flex;
        flex-direction: column;
    }
    
}

