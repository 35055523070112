.search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1.0625rem;
    background: #EEF1F4;
    border-radius: 10px;
    transition: background .17s;
}

.search:hover {
    background: #e7eaed;
}

.search__icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.6875rem;
}

.search__input {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 3.28571;
    width: 100%;
}

.search_list {
	position: absolute;
    top: calc(100% - 2rem);
    z-index: 1;
    width: 100%;
    border-radius: 0px 0px 14px 14px;
    overflow: hidden;
}


.search_list__container {
    padding-top: 2rem;
    background: #FFFFFF;
}
.search_list__container-enter {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
  }
  .search_list__container-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .17s, transform .17s;
  }
  .search_list__container-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .search_list__container-exit-active {
	opacity: 0;
	transform: translate3d(0, -4rem, 0);
	transition: opacity .17s, transform .17s;
  }

.search_result {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    padding: 1.25rem 1.4375rem;
    transition: background .17s;
    cursor: pointer;
	background: white;
}

.search_result__icon {
    width: .875rem;
    height: 1rem;
    fill: #4F82EC;
    margin-right: 1rem;
}
.search_result__text {
    width: 100%;
    position: relative;
}

.search_result__text:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.25rem;
    height: 1px;
    background: #EEF1F4;
}


.search_result__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0.4375rem;
}

.search_result__caption {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1;
    color: #4F82EC;
    margin: 0;
}

.search_result:hover {
    background: #F9FAFB;
}


.search_result__icon--group {
    fill: #FE8597;
}

.search_result__arrow {
    margin-left: auto;
    margin-right: 1rem;
    width: .5rem;
    height: 0.8rem;
    fill: #151515;
    transform: rotate(90deg);
}

.search_result__icon--group {
    fill: #FE8597;
}

.search_result__arrow {
    margin-left: auto;
    margin-right: 1rem;
    width: .5rem;
    height: 0.8rem;
    fill: #151515;
    transform: rotate(90deg);
    transition: transform .17s;
}

.search_result--group_item {
    padding-left: 3.4375rem;
    position: relative;
}

.search_result--group_item .search_result__icon {
    fill: #FE8597;
}

.search_result--group_item:before {
    content: '';
    position: absolute;
    left: 1.8rem;
    top: calc(50% - 1px);
    width: 0.975rem;
    height: 1.4px;
    background: #FE8597;
    transform-origin: left;
    transition: transform .17s;
    transform: scaleX(0);
}

.search_result--group_item:after {
    content: '';
    position: absolute;
    left: 1.8rem;
    top: -1px;
    bottom: -1px;
    width: 1.3px;
    background: #FE8597;
    transform-origin: top;
    transition: transform .17s;
    transform: scaleY(0);
}

.search_result_group__items-enter-done .search_result--group_item:before,
.search_result_group__items-enter-done .search_result--group_item:after {
    transform: none;
}

.search_result--group_item:last-child:after {
    bottom: 50%;
}

.search_result--group {
    position: relative;
}

.search_result--group:after {
    content: '';
    position: absolute;
    left: 1.8rem;
    top: 70%;
    bottom: -1px;
    width: 1.3px;
    background: #FE8597;
    transition: transform .18s;
    transform: scaleY(0);
    transform-origin: top;
}

.search_result_group__items {
    opacity: 0;
    max-height: 0;
    transition: max-height .3s, opacity .17s;
    will-change: max-height;
    pointer-events: none;
}

.is-open .search_result--group:after {
    transform: scale(1);
}

.is-open .search_result--group {
    background: #FFEEEE;
}

.is-open .search_result__arrow {
    transform: rotate(90deg) scaleX(-1);
}

.is-open .search_result_group__items {
    opacity: 1;
	pointer-events: all;
}


.search_result--group_item .search_result__text {
    transition: transform .3s, opacity .3s;
    transform: translate3d(-1rem, 0, 0);
    opacity: 0;
}

.search_result_group__items-enter-done .search_result--group_item .search_result__text, .search_result_group__items-enter-done .search_result--group_item .search_result__icon {
    transform: none;
    opacity: 1;
}

.search_result--group_item .search_result__icon {
    transform: scale3d(.5, .5, 1);
    opacity: 0;
    transition: transform .3s, opacity .3s;
}

.search__clear {
    width: 1rem;
    height: 1rem;
    background: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    transition: background .17s;
    padding: 0;
    flex: none;
	cursor: pointer;
}

.search__clear_icon {
    fill: white;
    width: 50%;
    height: 50%;
}

.search__clear:hover {
    background: #F2453D;
}

.search_result--info {
    
    font-family: 'Golos';
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 1.125rem;
    margin: 0;
    padding: 1.625rem 1.4375rem;
	cursor: default;
	justify-content: space-between;
}
.search_result--info:hover {
	background: white;
}
.search__clear_history {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: .7em;
    padding: 0.625em 1.25em;
    border-radius: 8px;
    transition: background .17s;
    cursor: pointer;
    color: #F2453D;
    border: 1px solid #EEF1F4;
}

.search__clear_history:hover {
    background: #fff3f3;
}


@media (max-width: 768px) {
	.header__search {
		flex: none;
		margin: 0;
		position: relative;
		z-index: 8;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		padding: 1.71429rem;
		transform: translate3d(0, calc(var(--app-height) - 100% - 4.5rem), 0);
		transition: transform .25s, background .17s;
		
	}
	.header__search.is-clicked {
		transform: none;
		background: rgba(227, 231, 235, 0.64);
	
	}

	
	
	.search {
		background: white;
	}
	.search:hover {
		background: #fff;
	}
	
	.search__input {
		font-size: 1rem;
	}
	.is-clicked .search {
		border-radius: 10px 10px 0 0;
	}
	.search_list {
		z-index: 11;
		position: fixed;
		top: 5.1rem;
		bottom: 0;
		background: white;
		border-top: 1px solid #EEF1F4;
		left: 1.71429rem;
		right: 1.71429rem;
		bottom: 1.71429rem;
		width: auto;
		opacity: 0;
		pointer-events: none;
        border-radius: 0 0 10px 10px;
		transition: opacity .3s .15s;
	}
	.search_list.is-visible {
		opacity: 1;
		pointer-events: all;
	}
	.search_list .simplebar-content {
		height: 100%;
	}
	.search_list__container {
		padding-bottom: 5.2rem;
	}
	
	
	.search_list__close_wrapper {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 1.53846rem 2.23077rem;
		background: white;
		border-top: 1px solid #EEF1F4;
		background: white;
		z-index: 2;
	}
	
	.border_btn {
		font-size: 1rem;
		font-family: 'Inter';
		font-weight: 500;
		border: 1px solid #EEF1F4;
		border-radius: 8px;
		padding: 0.4rem 0.9rem;
	}
		
	.search_result__title {
		font-size: 1rem;
		line-height: 1.3;
		margin-bottom: 0.2rem;
	}
	
	.search_result {
		padding: 1rem;
		padding-right: 2.4rem;
	}
	
	.search_result__icon {
		margin-right: 0.8rem;
		width: 1.4rem;
		height: 1.4rem;
	}
	
	.search_result__caption {
		font-size: 1rem;
		line-height: 1.3;
	}
	
	.search_result--group_item {
		padding-left: 3.4375rem;
	}
	
	.search_result--group {
		padding-right: 1.5rem;
	}
	.search_result--group:after, .search_result--group_item:before, .search_result--group_item:after {
		left: 1.55rem;
	}
	
		
}