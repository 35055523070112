
.review {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
	background: rgba(227, 231, 235, 0.64);
}

.review-enter {
	opacity: 0;
	transform: translate3d(0, 4rem, 0);
  }
  .review-enter-active {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .17s, transform .17s;
  }
  .review-exit {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .review-exit-active {
	opacity: 0;
	transform: translate3d(0, 4rem, 0);
	transition: opacity .17s, transform .17s;
  }
.review__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.review__content {
	position: relative;
}


@media (max-width: 768px)  {


	.review__controls {
		padding: 0 1.71429rem 1.35714rem;
		width: calc(100% + 2.71428rem);
		left: -1.35714rem;
		position: relative;
		margin-bottom: 0.85714rem;
	}

	.review {
		padding: 1.71429rem;
	}

}


@media (max-height: 45rem) {
    .review {
        display: block;
    }
}