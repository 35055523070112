button {
	background: none;
    border: none;
    outline: none;
}

.map, .map-container.mapboxgl-map {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  }
  .marker {
	  width: 2em;
	  height: 2em;
	  border-radius: 50%;
	  background: teal;
  }
  .cluster {
	  background: red;
  }


  .point_button {
    width: 5.5em;
    height: 5.5em;
    position: relative;
	cursor: pointer;
	opacity: 0;
	transform-origin: bottom;
	transform: scale(.85);
	animation: intro .25s forwards ;
    will-change: transform, opacity;
    
}

.point_button__bg {
	position: absolute;
    left: 50%;
    top: 83%;
    transform: translate(-50%, -50%);
	pointer-events: none;
    width: 6.6875rem;
    height: 7.625rem;
}
.point_button__counter {
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1.6em;
    color: white;
}

.point_button__icon {
	position: relative;
	z-index: 4;
    width: 100%;
    height: 100%;
	display: flex;
	align-items: center;
    justify-content: center;
}

.point_button__icon svg {
	position: relative;
	z-index: 1;
	width: 50%;
    height: 50%;
    fill: white;
}
.point_button__tooltip {
    position: absolute;
    left: calc(100% + 1rem);
    top: 50%;
    transform: translate3d(2em, -50%, 0);
    pointer-events: none;
    opacity: 0;
    transition: transform .25s, opacity .25s;
	min-width: 25.4375em;
}

.point_button:hover .point_button__tooltip {
    opacity: 1;
    transform: translate3d(0, -50%, 0);
}

.point_button:before,
.point_button:after{
    content: '';
    position: absolute;
    left: 50%;
    top: 83%;
    width: 6.6875rem;
    height: 7.625rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06));
    z-index: 2;
    clip-path: path('M53.5005 6C37.2306 6 24 19.2183 24 35.4784C24 45.247 28.4986 52.2303 34.1581 58.5915C39.8176 64.9542 46.649 70.8825 52.0388 78.9615C52.7341 80.004 54.2658 80.004 54.9603 78.9615C60.3501 70.8813 67.1815 64.953 72.841 58.5915C78.5005 52.2289 82.9991 45.2454 82.9991 35.4784C82.9991 19.2171 69.7698 6 53.4987 6H53.5005Z');
    opacity: 0;
    transition: opacity .25s, transform .25s;
    transform: translate(-50%, -50%) scale(.5);
}

.point_button:after {
	background: black;
}
.point_button:hover:before,
.point_button.is-active:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}




.point_button--user:before,
.point_button--user:after {
    content: none;
}
.point_button--user {
    cursor: grab;
}
.on-drag .point_button--user {
  animation: none;
  opacity: .6;
  transform: scale(1.2);
  z-index: 10;
}

.point_button--user .point_button__bg {
    transition: filter .17s;
}

.point_button--user:hover .point_button__bg {
    filter: hue-rotate(-3deg) contrast(1.3);
}


.mapboxgl-marker:hover {
    z-index: 2;
}
.mapboxgl-marker.on-drag {
    z-index: 10;
}



.tooltip {
    background: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);
    padding: 1.5rem;
    border-radius: 16px;
	text-align: left;
}

.tooltip:before {
	content: '';
    position: absolute;
    width: 2rem;
    z-index: -1;
    height: 2rem;
    right: calc(100% - 1.5rem);
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
    transform: rotate(45deg);
    border-radius: 6px;
}

.tooltip__head {
	position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.tooltip__icon {
    position: relative;
    width: 3rem;
    height: 3rem;
	margin-right: 1rem;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(-45deg, #4F82EC, #FE8597);
    border-radius: 50%;
    
}


.tooltip__icon_symbol {
    width: 50%;
    height: 50%;
    position: relative;
    z-index: 1;
}

.tooltip__icon:before {
    content: '';
    position: absolute;
    width: 2.8125rem;
    height: 2.8125rem;
    background: #EEF1F4;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px white;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
}

.tooltip__name {
    font-family: 'Golos';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    margin: 0;
    margin-bottom: 0.45rem;
}

.tooltip__address {
    margin: 0;
    font-family: 'MuseoSansCyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
}

.tooltip__content {
	position: relative;
    font-family: 'MuseoSansCyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.375rem;

	margin-top: 0.875rem;
	padding-top: 0.875rem;
}
.tooltip__content::after  {
	content: '';
	position: absolute;
	top: 0;
	left: 4rem;
	right: -1.5rem;
	height: 1px;
	background: #EEF1F4;
}




@keyframes intro {
	to {
		opacity: 1;
		transform: scale(1);
	}
}


.ripple_container {
	position: absolute;
    left: 0.57em;
    right: 0;
    bottom: -0.5em;
    overflow: hidden;
    top: 0.5em;
    clip-path: path('M29.5005 0C13.2306 0 0 13.2183 0 29.4784C0 39.247 4.49861 46.2303 10.1581 52.5915C15.8176 58.9542 22.649 64.8825 28.0388 72.9615C28.7341 74.0041 30.2658 74.0041 30.9603 72.9615C36.3501 64.8813 43.1815 58.953 48.841 52.5915C54.5005 46.2289 58.9991 39.2454 58.9991 29.4784C58.9991 13.2171 45.7698 0 29.4987 0H29.5005Z');

    

}
.point_button .ripple_container {
    left: 50%;
    top: 83%;
    bottom: auto;
    right: auto;
    transform: translate(-50%, -50%);
    width: 6.6875rem;
    height: 7.625rem;
    overflow: hidden;
       clip-path: path('M53.5005 6C37.2306 6 24 19.2183 24 35.4784C24 45.247 28.4986 52.2303 34.1581 58.5915C39.8176 64.9542 46.649 70.8825 52.0388 78.9615C52.7341 80.004 54.2658 80.004 54.9603 78.9615C60.3501 70.8813 67.1815 64.953 72.841 58.5915C78.5005 52.2289 82.9991 45.2454 82.9991 35.4784C82.9991 19.2171 69.7698 6 53.4987 6H53.5005Z');
}

.ripple {
	width: 20px;
	height: 20px;
	position: absolute;
	background: white;
	display: block;
	content: "";
	border-radius: 9999px;
	opacity: .3;
	animation: 0.9s ease 1 forwards ripple-effect;
  }
  
  @keyframes ripple-effect {
	0% {
	  transform: scale(1);
	  opacity: .3;
	}

	100% {
	  transform: scale(20);
	  opacity: 0;
	}
  }
  
  

@media (max-width: 1376px) {
    .point_button__bg,
    .point_button:before,
    .point_button:after {
        top: 76%;
        transform-origin: left top;
    }
    
    .point_button__icon {
        height: 80%;
    }
    
    
    .point_button:hover:before, .point_button.is-active:after, .ripple_container {
        transform: translate(-50%, -50%) scale(0.8);
    }
}
@media (max-width: 768px) {
    .point_button__bg,
    .point_button:before,
    .point_button:after {
        top: 80%;
        transform-origin: left top;
    }
    .point_button:hover:before, .point_button.is-active:after, .ripple_container {
        transform: translate(-50%, -50%) scale(0.9);
    }
    .point_button__counter {
        font-size: 1.3em;
    }
    .point_button__icon {
        height: 60%;
    }
    .point_button__tooltip {
        left: 50%;
        top: auto;
        top: -8.7rem;
        transform: translate3d(-50%, 2rem, 0);
    }
    
    .point_button:hover .point_button__tooltip {
        transform: translate3d(-50%, 0, 0);
    }
    
    .tooltip:before {
        top: auto;
        left: 0;
        right: 0;
        bottom: -0.4rem;
        width: 1.8rem;
    }
    
    
}
@media (max-width: 375px) {
    .point_button:hover:before, .point_button.is-active:after, .ripple_container {
        transform: translate(-50%, -50%) scale(0.75)
    }
    
}