.preloader {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    transition: all .5s .5s;
    
}

.preloader.is-hidden {
    opacity: 0;
    pointer-events: none;
}

.preloader__icon {
    width: 15em;
    height: 7em;
}
.preloader__icon svg {
    width: 100%;
    height: 100%;
}

.preloader__icon path,
.preloader__icon g {
    transform-box: fill-box;
}

#p_back_1 {
    transform: rotateY(-180deg) translate(12px, 10px);
    transform-origin: left top;
    will-change: transform;
    animation-duration: .5s;
    animation-delay: 1.5s;
}

.preloader__icon svg {
    transform: translate3d(0, 3em, 0) scale(.9);
    opacity: 0;
    animation-duration: .7s;
    animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation-delay: 1.25s;
    will-change: transform, opacity;
}

#p_back_2 {
    transform: rotateZ(135deg);
    opacity: 0;
    transform-origin: left center;
    transition: all .5s 1s;
    animation-duration: .5s;
    animation-delay: 1.5s;
    will-change: transform, opacity;
}

#p_back_1 path
{
    fill: #3963bb;
    animation: fill .5s forwards 1.5s
    
}

.preloader__icon svg,
  #p_back_2,
  #p_back_1 {
    animation-name: intro;
    animation-fill-mode: forwards;
}


@keyframes intro {
    to {
        transform: none;
        opacity: 1;
    }
}

@keyframes fill {
    to {
        fill: #FE8597;
    }
}


.app__second_preloader {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app__second_preloader svg {
    width: 5rem;
    height: 5rem;
}
