.zoom_controls__item {
    display: block;
}

.zoom_controls_btn {
    width: 3rem;
    height: 3.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .17s, opacity .17s;
    background: white;
    cursor: pointer;
}

.zoom_controls {
    border-radius: 10px;
    overflow: hidden;
    pointer-events: auto;
}

.zoom_controls_btn__icon {
    width: 1rem;
    height: 1rem;
	fill: #151515;
}

.zoom_controls_btn:hover {
    background: #EEF1F4;
}

.zoom_controls_btn.is-disabled {
    pointer-events: none;
    opacity: .5;
}
