.point_card {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(227, 231, 235, 0.64);
    padding: 2.125rem;
	z-index: 10;
}

.point_card__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.point_card__content {
	position: relative;
	z-index: 2;
    height: 100%;
    width: 35.25rem;
    background: #FFFFFF;
    box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
    border-radius: 14px;
	padding: 2.125rem;
}

.point_card-enter {
	opacity: 0;
  }
  .point_card-enter-active {
	opacity: 1;
	transition: opacity 200ms;
  }
  .point_card-exit {
	opacity: 1;
  }
  .point_card-exit-active {
	opacity: 0;
	transition: opacity 200ms;
  }
  .point_card-enter .point_card__content {
	  transform: translate3d(0, 5rem, 0);
  }
  .point_card-enter-active .point_card__content {
	transition: transform .15s
  }
  .point_card-enter-active .point_card__content,
  .point_card-exit .point_card__content {
	transform: translate3d(0, 0, 0);
	
}
.point_card-exit-active .point_card__content {
	transform: translate3d(-5rem, 0, 0);
	transition: transform .15s
}

.point_card__head {
	margin-bottom: 2.125rem;
}
.point_card__title {
	font-family: 'Golos', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 1.875rem;
	line-height: 1.333;
	margin: 0;
	max-width: 86%;
}

.point_card__subheading {
	font-family: 'Golos', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 1.5rem;
	margin: 0;
	display: flex;
    align-items: center;
}
.point_card__short_description {
	font-family: 'MuseoSansCyrl';
	font-style: normal;
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.71429;
    color: #6E6D7A;

}
.point_card_tabs__btns {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #EEF1F4;
	border-radius: 6px;
	margin-bottom: 2rem;
}
.point_card_tabs__btn {
	position: relative;
	z-index: 2;
	width: 33%;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 0.875rem;
	padding: 0.8rem 0;
	cursor: pointer;
}
.point_card_tabs__btn:nth-of-type(2):before,
.point_card_tabs__btn:nth-of-type(2):after {
	content: '';
	position: absolute;
	top: 33%;
	bottom: 33%;
	width: 1px;
	background: #B8BFC7;
	transition: opacity .2s;
}
.point_card_tabs__btn:nth-of-type(2):before {
	left: 0;
}
.point_card_tabs__btn:nth-of-type(2):after {
	right: 0;
}
.point_card_tabs__btn_highlight--on-active-1 ~ .point_card_tabs__btn:nth-of-type(2):before,
.point_card_tabs__btn_highlight--on-active-2 ~ .point_card_tabs__btn:nth-of-type(2):before,
.point_card_tabs__btn_highlight--on-active-2 ~ .point_card_tabs__btn:nth-of-type(2):after, 
.point_card_tabs__btn_highlight--on-active-3 ~ .point_card_tabs__btn:nth-of-type(2):after   {
	opacity: 0;
	transition-delay: .15s;
}
 
.point_card_tabs__btn_highlight {
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
    bottom: 0.125rem;
    width: 33%;
    background: #FFFFFF;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    z-index: 1;
    transition: transform .2s;
}
 .point_card_tabs__btn_highlight--on-active-2 {
    transform: translate3d(100%, 0, 0);
}
 .point_card_tabs__btn_highlight--on-active-3 {
    transform: translate3d(200%, 0, 0);
}



.point_card_tabs__tab--enter{
	opacity:0;
	transform: translate3d(-2em, 0, 0);
 }
.point_card_tabs__tab--exit{
	transform:scale3d(1, 1, 1);
	opacity: 1;
 }
.point_card_tabs__tab--enter-active{
	opacity:1;
	transform: translate3d(0, 0, 0);
 }
.point_card_tabs__tab--exit-active{
	transform:scale3d(.95 ,.95, 1);
	opacity: 0;
 }
.point_card_tabs__tab--enter-active,
.point_card_tabs__tab--exit-active{
	transition: transform .17s, opacity .17s;
 }

 .point_card_tabs__wrapper {
    width: calc(100% + 1rem);
}

.point_card_tabs__tab {
    padding-right: 1rem;
    margin-bottom: 1.5rem;
}

.point_card_tab__categories {
	list-style: none;
	padding: 0;
	padding-top: 1.75rem;
	margin: 0;
}

.point_category {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.point_category + .point_category {
	margin-top: 1.875rem;
}

.point_category__icon {
	width: 2.125rem;
	height: 2.125rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	margin-right: 0.875rem;
}

.point_category__icon--type-1 {
	background: #4F82EC;
}
.point_category__icon--type-2 {
	background: #FE8597;
}
.point_category__icon--type-3 {
	background: #3BDBB9;
}
.point_category__icon_content  {
	width: 50%;
	height: 60%;
	fill: white
}
.point_category__name {
	font-size: 0.875rem;
	font-weight: 300;
	line-height: 1.3;
	margin: 0;
	max-width: 53%;
	font-family: 'MuseoSansCyrl';
}

.point_card_tab__description *,
.point_card__text {
    font-size: 0.875rem;
    line-height: 1.8;
    font-family: 'MuseoSansCyrl';
    font-weight: 300;
	color: #6E6D7A
}
.point_card__text span {
    font-family: inherit;
}

.point_card_tab__description a {
    color: #4F82EC;
}
.point_card_tab__description a:hover {
    color: #2756b7;
}


.point_card .simplebar-scrollbar {
    width: 2px;
    background: #EEF1F4;
}

.point_card .simplebar-scrollbar.simplebar-visible:before {
    width: 2px;
    left: 0;
    right: 0;
    opacity: 1;
    background: #6E6D7A;
}

.point_card .simplebar-track.simplebar-vertical {
    width: 2px;
    background: #EEF1F4;
}


.point_card__review_block {
    border-top: 1px solid #EEF1F4;
    margin-top: auto;
    padding-top: 1.5rem;
    padding-right: 1rem;
}

.ymap_link__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.8;
    /* identical to box height, or 171% */
    display: flex;
    align-items: center;
    /* Main / Blue */
    color: #4F82EC;
    margin-bottom: 1.25rem;
}

.ymap_link__icon {
    width: 0.45em;
    height: .7em;
    fill: currentColor;
    margin-left: 1.25em;
}

.ymap_link__pic {
    width: 100%;
    height: 11.1875rem;
    border: 1px solid #EEF1F4;
    border-radius: 6px;
    background: #EEF1F4;
}


.ymap_link__pic {
    width: 100%;
    height: 11.1875rem;
    border: 1px solid #EEF1F4;
    border-radius: 6px;
    background: #EEF1F4;
}

.ymap_link {
    display: block;
}

.ymap_link:hover .ymap_link__text {
    color: #2756b7;
}


.point_card__links {
    list-style: none;
    padding: 0;
    margin: 0;
margin-top: 1.25rem;}

.point_card_link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: inherit;
    padding: 1rem 0;
    transition: background .17s;

}

.point_card_link__text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3;
    margin: 0;
    margin-bottom: 0.25rem;
    transition: color .2s;
}

.point_card_link__caption {
    font-family: 'MuseoSansCyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1;
    color: #6E6D7A;
    margin: 0;
}

.point_card_link__icon {
    width: .5rem;
    height: 0.65rem;
    fill: #4F82EC;
    transition: transform .2s;
}

.point_card__link_item + .point_card__link_item {
    border-top: 1px solid #EEF1F4;
}


.point_card_link:hover {
    color: #4F82EC;
    
    
}


.point_card_subheading__icon {
    width: 2.125rem;
    height: 2.125rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
}

.point_card_subheading__icon--social {
    background: #4F82EC;
}

.point_card_subheading__icon--contacts {
    background: #3BDBB9;
}

.point_card_subheading__icon--address {
    background: #FE8597;
}

.point_card_subheading__content {
    width: 50%;
    height: 60%;
	fill: white
}

.point_card__close {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: #EEF1F4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    transition: background .17s;
}

.point_card__close_icon {
    width: 40%;
    height: 50%;
    fill: #6E6D7A;
    transition: fill .17s;
}

.point_card__close:hover {
    background: #c5c7ca;
}

.point_card__close:hover .point_card__close_icon {
    fill: white;
}

.point_card__review_btn {
    width: 100%;
  }
  .point_card_tabs__content {
    display: flex;
    flex-direction: column;
}

@media (max-width: 768px) {
    .point_button__tooltip {
        left: 50%;
        top: auto;
        top: -8.7rem;
        transform: translate3d(-50%, 2rem, 0);
    }
    
    .point_button:hover .point_button__tooltip {
        transform: translate3d(-50%, 0, 0);
    }
    
    .tooltip:before {
        top: auto;
        left: 0;
        right: 0;
        bottom: -0.4rem;
        width: 1.8rem;
    }
    
    .point_card__content {
        width: auto;
        padding: 1.35714rem 1.71429rem;
        border-radius: 10px;
        max-width: 35.25rem;
        width: 100%;
    }
    
    .point_card {
        padding: 1.71429rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .point_card__controls {
        padding: 0 1.71429rem 1.35714rem;
        width: calc(100% + 2.71428rem);
        left: -1.35714rem;
        position: relative;
        margin-bottom: 0.85714rem;
    }
    
    .point_card__title {
        max-width: 100%;
        font-size: 1.71429rem;
        line-height: 1.16667;
    }
    
    .point_card__short_description {
        font-size: 1rem;
        line-height: 1.57143;
    }
    
    .point_card_tabs__btn {
        font-size: 1rem;
    }
    
    .point_card_tabs__btns {
        margin-bottom: 1.71429rem;
    }
    
    .point_card__subheading {
        font-size: 1.71429rem;
        font-weight: 500;
    }
    
    .point_category__icon {
        width: 2.42857rem;
        height: 2.42857rem;
        border-radius: 10px;
        flex: none;
    }
    
    .point_category__name {
        font-size: 1rem;
        line-height: 1.28571;
        max-width: 100%;
    }
    
    .point_card_tab__description *, .point_card__text {
        font-size: 1rem;
        line-height: 1.57143;
    }
    
    .point_category + .point_category {
        margin-top: 2.14286rem;
    }
    
    .point_card_tabs__tab {
        margin-bottom: 2.5rem;
    }
    
    .point_card__review_block {
        padding-top: 1.71429rem;
    }
    
    .point_card_tab__categories {
        padding-top: 2rem;
    }
    
    .point_card_subheading__content {
    }
    
    .point_card_subheading__icon {
        flex: none;
        width: 2.42857rem;
        height: 2.42857rem;
        border-radius: 10px;
        margin-right: 1.14286rem;
    }
    
    
    
    .ymap_link__text {
        font-size: 1rem;
        line-height: 1.71429rem;
    }
    
    .ymap_link__pic {
        object-fit: cover;
    }
    
    .point_card_link__text {
        font-size: 1rem;
    }
    
    .point_card_link__caption {
        font-size: 0.85714rem;
    }
    
}