.feedback {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
}


.feedback__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	z-index: 1;
}

.feedback__content {
    position: relative;
    z-index: 2;
}

.feedback:before, .feedback:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    background: #F9FAFB;
    z-index: 0;
}

.feedback:before {
    left: 0;
}

.feedback:after {
    right: 0;
}


.feedback-enter:before {
	opacity: 0;
	transform: translate3d(-20rem, 0, 0);
  }
  .feedback-enter:after {
	opacity: 0;
	transform: translate3d(20rem, 0, 0);
  }

  .feedback-enter .feedback__content {
	  opacity: 0;
	  transform: translate3d(0, 10rem, 0);
  }
  .feedback-enter-active .feedback__content {
	  opacity: 1;
	  transform: translate3d(0, 0, 0);
	  transition: opacity .3s .4s, transform .3s .4s;
  }
  .feedback-enter-active:before,
  .feedback-enter-active:after {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: opacity .3s, transform .3s;
  }
  .feedback-exit:before,
  .feedback-exit:after,
  .feedback-exit .feedback__content  {
	opacity: 1;
	transform: translate3d(0, 0, 0);
  }
  .feedback-exit-active:before {
	opacity: 0;
	transform: translate3d(-20rem, 0, 0);
	transition: opacity .3s .4s, transform .3s .4s;
  }
  .feedback-exit-active:after {
	opacity: 0;
	transform: translate3d(20rem, 0, 0);
	transition: opacity .3s .4s, transform .4s .4s;
  }
  .feedback-exit-active .feedback__content {
	opacity: 0;
	transform: translate3d(0, 5rem, 0);
	transition: opacity .3s, transform .3s;
}
.feedback__back_btn {
    position: absolute;
    left: 4.625rem;
    top: 4.625rem;
    z-index: 1;
}


.feedback-enter .feedback__back_btn {
	opacity: 0;
	transform: translate3d(-1rem, 0, 0);
}

.feedback-enter-active .feedback__back_btn {
	opacity: 1;
	transform: translate3d(0, 0, 0);
	transition: transform .3s, opacity .3s;
}

.feedback-exit .feedback__back_btn {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}
.feedback-exit-active .feedback__back_btn {
	opacity: 0;
	transform: translate3d(-1rem, 0, 0);
	transition: transform .3s, opacity .3s;
}
.back_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.back_btn__icon {
    width: 2.125rem;
    height: 2.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    /* Block */
    box-shadow: 0px 9px 48px rgba(0, 0, 0, 0.03);
    border-radius: 50%;
    margin-right: 0.75rem;
    transition: transform .17s;
}

.back_btn__icon_content {
    width: 30%;
    height: 30%;
    transform: scaleX(-1);
}

.back_btn__text {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 0.875rem;
    transition: color .17s;
}

.back_btn:hover .back_btn__text {
    color: #4f82ec;
}

.back_btn:hover .back_btn__icon {
    transform: scale(.85);
}

@media (max-width: 768px)  {

    .feedback__back_btn {
        position: relative;
        left: 0;
        top: 0;
        width: 25.5rem;
        justify-content: flex-start;
        margin-bottom: 1.71429rem;
    }

    .feedback {
        padding: 1.71429rem;
        flex-direction: column;
    }

    .back_btn__icon {
        width: 2.42857rem;
        height: 2.42857rem;
        margin-right: 0.85714rem;
    }

    .back_btn__text {
        font-size: 1rem;
    }

}

